import Vue from 'vue'
Vue.mixin({
    data() {
        return {
            cookieSet: this.getCookie('agecookies'),
            bfCookie: this.getCookie('black-friday-2024'),
            header: true,
            footer: true,
            fullscreen: false,
            scroll: false,
            notontop: false,
        }
    },
}) 